<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-tab :title="$t('Attribute') + ` 2`">
    <b-row>
      <b-col md="8">
        <b-col md="12">
          <b-form class="repeater-form" @submit.prevent="repeatVariation">
            <div
              v-for="(item, index) in variations"
              :id="String(index)"
              :key="index"
              class="d-flex align-items-center mb-1"
            >
              <b-form-group
                :label="$t('Variation') + ` ${index + 1}:`"
                label-cols-md="12"
                class="w-100"
              >
                <div class="bg-gray w-100">
                  <b-form-group :label="$t('Is color')+`:`" label-cols-md="12">
                    <b-form-checkbox
                      v-model="variations[index].is_color"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                  <b-form-group label="Name" label-cols-md="12">
                    <b-form-input
                      v-model="variations[index].name_type"
                      placeholder="Enter Variation Name, eg: Color"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="
                      variations &&
                        variations[index] &&
                        variations[index]['list']
                    "
                    label="Options"
                    label-cols-md="12"
                  >
                    <b-form
                      class="repeater-form"
                      @submit.prevent="repeatOption(index)"
                    >
                      <div
                        v-for="(i, idx) in variations[index]['list']"
                        :id="String(idx)"
                        :key="idx"
                        class="d-flex align-items-center mb-1 position-relative"
                      >
                        <b-form-input
                          v-model="variations[index]['list'][idx].name"
                          class="mr-3"
                          placeholder="Enter Option for Variation Name, eg: Red"
                        />
                        <b-button
                          v-if="variations[index].is_color"
                          :id="`popover-button-variant-${idx}`"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon rounded-circle mr-1"
                          @click="handlerShowColorPicker(index, idx)"
                        >
                          <feather-icon icon="LayersIcon" />
                        </b-button>
                        <sketch-picker
                          v-if="
                            variations[index]['list'][idx].showCode === true
                          "
                          v-model="variations[index]['list'][idx].code_color"
                          class="position-absolute"
                          style="right: -190px; top: 0; z-index: 8;"
                        />
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeOption(index, idx)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </b-form>
                  </b-form-group>

                  <b-form-group label-cols-md="12" label="">
                    <div class="fade-browse" @click="repeatOption(index)">
                      <feather-icon icon="PlusCircleIcon" class="mr-25" />
                      {{ $t('Add options') }} ({{ variations[index]['list'].length }}/20)
                    </div>
                  </b-form-group>
                </div>
              </b-form-group>
            </div>
          </b-form>
        </b-col>
        <b-col v-if="variations.length < 20" md="12">
          <b-form-group label="* Variation:" label-cols-md="12">
            <div class="fade-browse" @click="repeatVariation">
              <feather-icon icon="PlusCircleIcon" class="mr-25" />
              {{ $t('Add variations') }} ({{ variations.length }}/20)
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button
            type="submit"
            variant="primary"
            block
            @click="handleEditAttribute"
          >
            {{ $t('Update') }}
          </b-button>
        </b-col>
      </b-col>
      <b-col md="4" class="mt-top">
        <div class="bg-gray">
          <div><b>{{ $t('Variation and options youve typed for attribute 2')+`:` }}</b></div>
          <template
            v-if="
              variations && variations.length > 0 && variations[0].name !== null
            "
          >
            <div
              v-for="(item, index) in variations"
              :key="`id-${index}`"
              class="mt-1 d-block"
            >
              <b>{{ item.name_type }}:</b>

              <template
                v-if="
                  variations[index].list &&
                    variations[index].list.length > 0 &&
                    variations[index].list[0] !== ''
                "
              >
                <b-badge
                  v-for="(it, i) in item.list"
                  :key="`id-${i}`"
                  class="ml-1 mt-1 text-1"
                  pill
                  variant="light-dark"
                >
                  {{ it.name }}
                </b-badge>
              </template>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BTab,
  BBadge,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Sketch } from 'vue-color'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BTab,
    BBadge,
    BFormInput,
    BFormCheckbox,
    'sketch-picker': Sketch,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      variations: [],
      defaultColor: '#000',
      showCodeDefault: false,
      attributeList: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    handlerShowColorPicker(index, idx) {
      if (this.variations[index].list[idx].showCode === true) {
        this.variations[index].list[idx].showCode = false
      } else {
        this.variations[index].list.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.showCode = false
        })
        this.variations[index].list[idx].showCode = true
      }
    },
    repeatVariation() {
      this.variations.push({
        is_color: false,
        name: null,
        list: [],
      })
    },
    repeatOption(index) {
      this.variations[index].list.push({
        id: null,
        name: null,
        code_color: this.defaultColor,
        showCode: this.showCodeDefault,
        is_active: 1,
      })
    },
    async removeOption(parentIndex, index) {
      let id = null
      id =
        this.variations[parentIndex].list[index - 1] !== undefined
          ? this.variations[parentIndex].list[index - 1].id
          : this.variations[parentIndex].list[index].id
      this.variations[parentIndex].list.splice(index, 1)
      if (this.variations[parentIndex].list.length === 0) {
        this.variations.splice(parentIndex, 1)
      }
      if (id !== null && id !== undefined) {
        try {
          const res = await Request.delete(
            this.$http,
            `${process.env.VUE_APP_API_URL}/second_attribute/${id}?site_id=${
              JSON.parse(localStorage.getItem('siteID')).id
            }`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.variations = []
              this.loadList()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(this.showError(res.data.error, ',')),
                },
              })
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
    async handleEditAttribute() {
      try {
        if (this.variations && this.variations.length > 0) {
          this.variations.map(async x => {
            if (x.list && x.list.length > 0) {
              x.list.map(async (y, index) => {
                const params = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  name: y.name,
                  is_active: 1,
                  name_type: x.name_type,
                  code_color: x.is_color ? y.code_color.hex : '',
                }
                let res = null
                if (y.id !== null) {
                  res = await Request.put(
                    this.$http,
                    `${process.env.VUE_APP_API_URL}/second_attribute/${y.id}`,
                    params,
                  )
                } else {
                  res = await Request.post(
                    this.$http,
                    `${process.env.VUE_APP_API_URL}/second_attribute`,
                    params,
                  )
                }
                if (res.status === 200) {
                  if (res.data.status) {
                    if (index === x.list.length - 1) {
                      this.loadList()
                    }
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Opps! Something wrong',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: String(this.showError(res.data.error, ',')),
                      },
                    })
                  }
                }
              })
            }
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      try {
        this.variations = []
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/second_attribute/tree?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const array = []
            res.data.data.map(item => {
              const newItem = {
                id: item.id,
                name_type: item.name_type,
                list: [],
                is_color: Boolean(
                  item.list[0].code_color !== null &&
                    item.list[0].code_color !== ''
                    ? true
                    : false,
                ),
              }
              item.list.map(x => {
                const newX = {
                  id: x.id,
                  is_active: x.is_active,
                  name: x.name,
                  showCode: this.showCodeDefault,
                  code_color:
                    x.code_color === null ? this.defaultColor : x.code_color,
                }
                newItem.list.push(newX)
              })
              array.push(newItem)
            })
            this.variations = array
            this.attributeList = this.variations
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.inline-block {
  display: block;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
.text-1 {
  font-size: 1rem;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.mt-top {
  margin-top: 2.5rem;
}
</style>
