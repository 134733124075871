var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"title":_vm.$t('Attribute') + " 3"}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form',{staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.repeatVariation($event)}}},_vm._l((_vm.variations),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-items-center mb-1",attrs:{"id":String(index)}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('Variation') + " " + (index + 1) + ":","label-cols-md":"12"}},[_c('div',{staticClass:"bg-gray w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('Is color')+":","label-cols-md":"12"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.variations[index].is_color),callback:function ($$v) {_vm.$set(_vm.variations[index], "is_color", $$v)},expression:"variations[index].is_color"}})],1),_c('b-form-group',{attrs:{"label":"Name","label-cols-md":"12"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Variation Name, eg: Color"},model:{value:(_vm.variations[index].name_type),callback:function ($$v) {_vm.$set(_vm.variations[index], "name_type", $$v)},expression:"variations[index].name_type"}})],1),(
                    _vm.variations &&
                      _vm.variations[index] &&
                      _vm.variations[index]['list']
                  )?_c('b-form-group',{attrs:{"label":"Options","label-cols-md":"12"}},[_c('b-form',{staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.repeatOption(index)}}},_vm._l((_vm.variations[index]['list']),function(i,idx){return _c('div',{key:idx,staticClass:"d-flex align-items-center mb-1 position-relative",attrs:{"id":String(idx)}},[_c('b-form-input',{staticClass:"mr-3",attrs:{"placeholder":"Enter Option for Variation Name, eg: Red"},model:{value:(_vm.variations[index]['list'][idx].name),callback:function ($$v) {_vm.$set(_vm.variations[index]['list'][idx], "name", $$v)},expression:"variations[index]['list'][idx].name"}}),(_vm.variations[index].is_color)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mr-1",attrs:{"id":("popover-button-variant-" + idx),"variant":"outline-primary"},on:{"click":function($event){return _vm.handlerShowColorPicker(index, idx)}}},[_c('feather-icon',{attrs:{"icon":"LayersIcon"}})],1):_vm._e(),(
                          _vm.variations[index]['list'][idx].showCode === true
                        )?_c('sketch-picker',{staticClass:"position-absolute",staticStyle:{"right":"-190px","top":"0","z-index":"8"},model:{value:(_vm.variations[index]['list'][idx].code_color),callback:function ($$v) {_vm.$set(_vm.variations[index]['list'][idx], "code_color", $$v)},expression:"variations[index]['list'][idx].code_color"}}):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeOption(index, idx)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)}),0)],1):_vm._e(),_c('b-form-group',{attrs:{"label-cols-md":"12","label":""}},[_c('div',{staticClass:"fade-browse",on:{"click":function($event){return _vm.repeatOption(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add options'))+" ("+_vm._s(_vm.variations[index]['list'].length)+"/20) ")],1)])],1)])],1)}),0)],1),(_vm.variations.length < 20)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"* Variation:","label-cols-md":"12"}},[_c('div',{staticClass:"fade-browse",on:{"click":_vm.repeatVariation}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add variations'))+" ("+_vm._s(_vm.variations.length)+"/20) ")],1)])],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.handleEditAttribute}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1),_c('b-col',{staticClass:"mt-top",attrs:{"md":"4"}},[_c('div',{staticClass:"bg-gray"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('Variation and options youve typed for attribute 3')+":"))])]),(
            _vm.variations && _vm.variations.length > 0 && _vm.variations[0].name !== null
          )?_vm._l((_vm.variations),function(item,index){return _c('div',{key:("id-" + index),staticClass:"mt-1 d-block"},[_c('b',[_vm._v(_vm._s(item.name_type)+":")]),(
                _vm.variations[index].list &&
                  _vm.variations[index].list.length > 0 &&
                  _vm.variations[index].list[0] !== ''
              )?_vm._l((item.list),function(it,i){return _c('b-badge',{key:("id-" + i),staticClass:"ml-1 mt-1 text-1",attrs:{"pill":"","variant":"light-dark"}},[_vm._v(" "+_vm._s(it.name)+" ")])}):_vm._e()],2)}):_vm._e()],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }