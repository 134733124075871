<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <validation-observer ref="simpleRules">
    <Overplay v-if="isShow" />
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <BasicInformation
              id="BI"
              v-model="model"
              :categoryIdErrorText="categoryIdErrorText"
            />
          </Fieldset>
          <Fieldset id="salesinformation" :title="$t('Sales Information')">
            <SalesInformation id="SI" v-model="model" />
          </Fieldset>
          <Fieldset id="mediamanagement" :title="$t('Media Management')">
            <MediaManagement id="MM" v-model="model" />
          </Fieldset>
          <!-- <Fieldset id="shippingdetail" title="Shipping Detail"> -->
          <!-- <ShippingDetail id="SD" v-model="model" /> -->
          <!-- </Fieldset> -->
          <Fieldset id="SEO" :title="$t('SEO Information')">
            <SEOInfo id="SE" v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Warehouse')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_warehouse"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Is highlight')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_highlight"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  :placeholder="$t('Position')"
                  type="number"
                />
              </b-form-group>
              <hr>
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </div>
            </Fieldset>
            <Fieldset id="relatedOption" title="Options">
              <RelatedOptions
                v-model="model"
                :trademarkOptions="trademarkOptions"
                :originOptions="originOptions"
                :supplierOptions="supplierOptions"
              />
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */

/* eslint-disable no-unreachable */

import VueScrollactive from 'vue-scrollactive'
import {
  // BLink,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BasicInformation from './sync-product-components/BasicInformation.vue'
import SalesInformation from './sync-product-components/SalesInformation.vue'
import MediaManagement from './sync-product-components/MediaManagement.vue'
import RelatedOptions from './sync-product-components/RelatedOption.vue'
// import ShippingDetail from './sync-product-components/ShippingDetail.vue'

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BasicInformation,
    SalesInformation,
    MediaManagement,
    BButton,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    RelatedOptions,
  },
  mixins: [general],
  data() {
    return {
      isShow: false,
      categoryIdErrorText: null,
      trademarkOptions: null,
      originOptions: null,
      supplierOptions: null,
      // brand_id: null,
      // origin_id: null,
      // supplier_id: null,
      model: {
        // Common
        avatar: this.avatarDefault(),
        is_active: true,
        position: null,
        is_warehouse: false,
        is_highlight: false,
        category_id: null,
        category_name: null,
        brand_id: null,
        origin_id: null,
        supplier_id: null,
        ware_house: [],
        list_thumbnail: [],
        title_page: null,
        meta_keyword: [],
        meta_description: null,
        short_content: [
          {
            type: 'table',
            isShow: false,
            value: [
              {
                title: null,
                content: null,
              },
            ],
          },
          {
            type: 'content',
            isShow: false,
            value: null,
          },
        ],
      },
    }
  },
  async mounted() {
    this.loadDetail()
    this.trademarkOptions = await this.loadTrademarkList()
    this.originOptions = await this.loadOriginList()
    this.supplierOptions = await this.loadSupplierList()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_item_detail/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.model = {
              ...this.model,
              name: res.data.data.name,
              slug: this.convertToSlug(res.data.data.name),
              sku: res.data.data.item_sku,
              content: res.data.data.description,
              price: res.data.data.original_price,
            }

            res.data.data.attributes.map(val => {
              this.model.short_content[0].value = [
                {
                  title: val.attribute_name,
                  content: val.attribute_value,
                },
              ]
            })
          } else {
            this.model.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.model.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.isShow = true
      this.$refs.simpleRules.validate().then(async success => {
        if (this.model.category_id === null) {
          this.categoryIdErrorText = 'The Category field is required'
          this.isShow = false
        }
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              short_content: JSON.stringify(this.model.short_content),
              meta_keyword: JSON.stringify(this.model.meta_keyword),
            }
            const params = {
              sku: this.model.sku,
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              is_highlight: Number(this.model.is_highlight === true ? 1 : 0),
              is_warehouse: Number(this.model.is_warehouse === true ? 1 : 0),
              position: Number(this.model.position),
              category_id: this.model.category_id,
              price: this.model.price,
              slug: this.model.slug,
              name: this.model.name,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              short_content: JSON.stringify(this.model.short_content),
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              translation: JSON.stringify(translation),
              avatar: this.model.avatar,
              trademark_id: this.model.brand_id,
              origin_id: this.model.origin_id,
              supplier_id: this.model.supplier_id,
              e_commerce: 1,
              code_e_commerce: this.$route.params.id,
              warehouse: [],
              price_json: JSON.stringify({}),
            }
            if (this.model.ware_house && this.model.ware_house.length > 0) {
              this.model.ware_house.map(item => {
                params.warehouse.push(JSON.stringify(item))
              })
            } else {
              params.warehouse.push(
                JSON.stringify({
                  // avatar: this.avatarDefault(),
                  is_warehouse: false,
                  price: this.model.price,
                  quantity: 0,
                  is_active: true,
                  sku: this.model.sku,
                  is_default: true,
                  price_json: JSON.stringify({}),
                }),
              )
            }
            if (
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  type_table: 'prod',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1

                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            // eslint-disable-next-line no-unreachable
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
            this.isShow = false
          }
        }
      })
    },
    async handleCreate(params) {
      if (
        params.warehouse.filter(val => JSON.parse(val).price_error).length >
          0 ||
        params.warehouse.filter(val => JSON.parse(val).sku_error).length > 0
      ) {
        return
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/product`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Product success',
            },
          })
          this.$router.push('/sales-channel/shopee')
          this.isShow = false
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
          this.isShow = false
        }
      }
    },
    async loadTrademarkList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademarks?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadOriginList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/origins?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadSupplierList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/suppliers?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
</style>
