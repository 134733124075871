<template>
  <!-- eslint-disable vue/html-closing-bracket-newline -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    title="Add category for new product"
    @ok="handlerSubmit"
  >
    <Overplay v-if="isShow" />
    <div class="container-ctrl">
      <b class="h1">Choose category for your product</b>
      <!-- <p>Please choose the right category for your product.</p> -->
      <hr>
      <div class="container-ctrl">
        <b-row>
          <b-col md="12">
            <div class="box-cate">
              <div class="input-search">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Search" />
                </b-input-group>
              </div>
              <div class="cate-list">
                <b-row>
                  <b-col md="3">
                    <ul class="list-item">
                      <li
                        v-for="(item, index) in tree"
                        :key="index"
                        class="item"
                        :class="{ active: selected1.name === item.name }"
                        @click.stop="setSelected1(item)"
                      >
                        <span>{{ item.name }}</span>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="3">
                    <ul class="list-item">
                      <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                      <li
                        v-for="(item2, index) in listCateChild1"
                        :key="index"
                        class="item"
                        :class="{
                          active: selected2.name === item2.name,
                          'd-none': selected1.name !== item2.parent_name,
                        }"
                        @click.stop="setSelected2(item2)"
                      >
                        <span>
                          {{ item2.name }}
                        </span>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="3">
                    <ul class="list-item">
                      <li
                        v-for="(item3, index) in listCateChild2"
                        :key="index"
                        class="item d-none-after"
                        :class="{
                          active: selected3.name === item3.name,
                          'd-none': selected2.name !== item3.parent_name,
                        }"
                        @click.stop="setSelected3(item3)"
                      >
                        <span>{{ item3.name }}</span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="current">
              <p>
                The currently selected :
                <b>{{ selected1.name }}</b>
                <b
                  v-if="
                    selected2 &&
                      selected2 !== '' &&
                      selected2.name !== undefined
                  "
                >
                  > {{ selected2.name }}</b
                >
                <b
                  v-if="
                    selected3 &&
                      selected3 !== '' &&
                      selected3.name !== undefined
                  "
                >
                  > {{ selected3.name }}</b
                >
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>
<script>
// import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  // BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  components: {
    // ValidationProvider,
    BFormInput,
    // BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: false,
      selected1: '',
      selected2: '',
      selected3: '',
      tree: [],
      listCateChild1: [],
      listCateChild2: [],
    }
  },
  // watch: {
  //   async value(val) {
  //     if (val) {
  //       const valSplit = val.category_id.split(',')
  //       await this.loadCategoryList(valSplit[0], 'level_1')
  //       await this.loadCategoryList(valSplit[1], 'level_2')
  //       await this.loadCategoryList(valSplit[2], 'level_3')
  //     }
  //   },
  // },
  mounted() {
    this.loadCategoryList()
  },
  methods: {
    handlerSubmit() {
      this.value.category_id = null
      const selected = {
        level_1: this.selected1,
        level_2: this.selected2,
        level_3: this.selected3,
      }
      if (selected.level_1.name === undefined) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Please select the category',
          },
        })
        return true
      }
      if (selected.level_2.name === undefined) {
        this.value.category_id = `${selected.level_1.id}`
        this.value.category_name = `${selected.level_1.name}`
        return true
      }
      if (selected.level_3.name === undefined) {
        this.value.category_id = `${selected.level_1.id},${selected.level_2.id}`
        this.value.category_name = `${selected.level_1.name} > ${selected.level_2.name}`
        return true
      }
      this.value.category_id = `${selected.level_1.id},${selected.level_2.id},${selected.level_3.id}`
      this.value.category_name = `${selected.level_1.name} > ${selected.level_2.name} > ${selected.level_3.name}`
      return true
    },
    setSelected1(val) {
      this.selected1 = val
      this.selected2 = ''
      this.selected3 = ''
      this.listCateChild1 = []
      // eslint-disable-next-line array-callback-return
      this.tree.map(x => {
        if (x.children && x.children.length > 0) {
          // eslint-disable-next-line array-callback-return
          x.children.map(y => {
            const yItem = {
              ...y,
              parent_root: x.id,
              parent_name: x.name,
              current_name: val.name,
            }
            this.listCateChild1.push(yItem)
          })
        }
      })
    },
    setSelected2(val) {
      this.selected2 = val
      this.selected3 = ''
      this.listCateChild2 = []
      // eslint-disable-next-line array-callback-return
      this.listCateChild1.map(x => {
        if (x.children && x.children.length > 0) {
          // eslint-disable-next-line array-callback-return
          x.children.map(y => {
            const yItem = {
              ...y,
              parent_root: x.id,
              parent_name: x.name,
              current_name: val.name,
            }
            this.listCateChild2.push(yItem)
          })
        }
      })
    },
    setSelected3(val) {
      this.selected3 = val
    },
    async loadSelectedCategory(id, level) {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/${id}?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (level === 'level_1') {
            this.setSelected1(result.data.data)
          }
          if (level === 'level_2') {
            this.setSelected2(result.data.data)
          }
          if (level === 'level_3') {
            this.setSelected3(result.data.data)
          }
          if (
            // eslint-disable-next-line operator-linebreak
            this.selected1 !== null &&
            // eslint-disable-next-line operator-linebreak
            this.selected2 !== null &&
            this.selected3 !== null
          ) {
            this.handlerSubmit()
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadCategoryList(id, level) {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          this.isShow = false
          const res = result.data.data
          this.tree = await res
          if (id !== undefined) {
            this.loadSelectedCategory(id, level)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem

        .active
          font-weight: 500
          background-color: #f5f5f5
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
