<template>
  <b-modal
    id="modal-variant"
    ok-title="Close"
    centered
    size="xl"
    title="Add new variant"
    @ok="handlerSubmit"
  >
    <Variant />
  </b-modal>
</template>

<script>
import Variant from '@/views/pages/attribute/AttributeEdit.vue'

export default {
  components: {
    Variant,
  },
  methods: {
    handlerSubmit() {
      this.$emit('loadVariant')
    },
  },
}
</script>
